import { useI18n } from 'vue-i18n'
import { notificationService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { INotification } from '@/apiClient/types/notification'

const sortByDesc =
  (key: keyof INotification) => (a: INotification, b: INotification) =>
    a[key] > b[key] ? -1 : 1

export const useNotificationStore = defineStore('notification', () => {
  const crud = useSocketsCrudFactory(notificationService, 'notification')

  const notifications = computed(() =>
    crud.list.value.sort(sortByDesc('createdAt')),
  )

  const notificationsUnseen = computed(() =>
    notifications.value.filter((notification) => notification.seenAt === null),
  )

  const notificationsSeen = computed(() =>
    notifications.value.filter((notification) => notification.seenAt !== null),
  )

  const notificationsUnseenCount = computed(
    () =>
      notificationsUnseen.value.filter(
        (notification) => notification.seenAt === null,
      ).length,
  )

  const getAllNotificationsUnseen = () =>
    crud.find({
      seenAt: null,
      $limit: 0,
    })

  const setAllAsSeen = async () => {
    const notificationsUnseen = await getAllNotificationsUnseen()

    if (!notificationsUnseen.data.length) return true

    const notificationsSetToSeen = notificationsUnseen.data.map(
      (notification) => ({
        id: notification.id,
        seenAt: new Date(),
      }),
    )

    return crud.patch(notificationsSetToSeen)
  }

  const setAsSeen = (id: number) => {
    crud.patch({
      id,
      seenAt: new Date(),
    })
  }

  return {
    notifications,
    notificationsUnseen,
    notificationsUnseenCount,
    notificationsSeen,
    setAsSeen,
    setAllAsSeen,
    ...crud,
  }
})
