import { i18n, initDBTranslations } from './i18n/index'
import App from './App.vue'
import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import router from '@/router'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import { isTawkEnabled } from './config'

loadFonts()
const app = createApp(App).use(router).use(store).use(i18n)

initDBTranslations()

// Error handler
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  ignoreErrors: [
    /Failed to fetch dynamically imported module/,
    /Importing a module script failed/,
    /Unable to preload CSS/,
  ],
})
// app.config.errorHandler = (error, vm, info) => {
//   console.log('TODO: send to sentry', error, vm, info)
// }

const propertyId = import.meta.env.VITE_TAWKTO_PROPERTY_ID
const widgetId = import.meta.env.VITE_TAWKTO_WIDGET_ID
if (isTawkEnabled) {
  app.use(TawkMessengerVue, {
    propertyId,
    widgetId,
  })
}

app.mount('#app')
